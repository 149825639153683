<template lang='pug'>
    v-container(fluid="").search-block.map-container.search-map.pa-0
        // Snacbar
        v-snackbar(v-model='snackbar.show' :color='snackbar.color' right top :timeout="4000")
          | {{snackbar.message}}
          v-btn(dark='' text='' @click='snackbar.show = false')
            | Close
        // Confirmation buy dialog
        v-dialog(v-model='confirmationDialog' width="500px" persistent transition="dialog-bottom-transition")
          div(style ='position:relative; height: 100%;')
            confirmDialog(v-if="confirmationDialog" :item="currentItem" @close="confirmationDialog=false")
            div(style='position:absolute; top:0; width:100%;')
              v-btn.mt-2.ml-2(absolute right  icon color='danger' style="top: -45px; z-index:999;" medium,  dark='', @click.native='confirmationDialog=false')
                v-icon(size="30") mdi-close-box
        // Filter dialog
        v-dialog(:fullscreen='isMobile' v-model="dialog" width="500px" transition="dialog-bottom-transition")
          v-card().filter-block
            v-toolbar.custom-toolbar-wrapper(dark="" height="75px" color="primary")
              .toolbar-header.mx-auto
                h2 Filter
              v-btn.toolbar-btn(icon dark absolute right  @click="dialog=false")
                v-icon(size="30") mdi-close-box
            .filter-block__content.container
              v-row
                v-col(cols="12" md="12").text-center
                  //- header
                  .filter-block__header-wrapper
                    h2.filter-block__header.text-uppercase.text-center.color-secondary scene type
                  //- type
                  <!--v-chip-group.custom-chip(v-model="chipType" active-class="transparent type-active").text-center-->
                    <!--v-chip(@click="" label).custom-chip__chip-->
                      <!--v-img(src="@/assets/images/filter-1.png" width="50px" height="auto")-->
                    <!--v-chip(@click="" label).custom-chip__chip-->
                      <!--v-img(src="@/assets/images/filter-2.png" width="50px" height="auto")-->
                    <!--v-chip(@click="" label).custom-chip__chip-->
                      <!--v-img(src="@/assets/images/filter-3.png" width="auto" height="50px")-->
                  <!--//- Category-->
                  v-autocomplete.custom-input.mt-5.mx-auto(v-model="category" rounded dense="" solo="" :items="categories" color="secondary" label="Category*" validate-on-blur="" clearable @change)
                  //- Subcategory
                  v-autocomplete.custom-input.mt-5.mx-auto(v-if="category" v-model="subcategory" rounded dense="" solo="" :items="subCategories[category]" color="secondary" label="subcategory" validate-on-blur="" clearable)
                  // v-select.custom-input.mx-auto(label="Sub categories" rounded dense solo="")
                  //- slider
                  label Distance ({{range}} miles)
                  v-slider.align-center.mt-10(v-model="range" :thumb-size="24" thumb-label="always" color="secondary" :max="max" :min="min" hide-details="")
                  //v-slider.align-center.mt-10(:thumb-size="24" color="secondary" v-model="range" :max="max" :min="min" hide-details="")
                  //- Date
                  v-menu(v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y="" min-width="290px")
                    template(v-slot:activator="{ on }")
                      v-text-field(v-model="date" label="Date of incident" prepend-icon="mdi-calendar" clearable readonly v-on="on")
                    v-date-picker(v-model="date" @input="menu2 = false")
                v-col(cols="12" md="12").text-center
                  .display-1 Presets
                  v-chip-group.mt-1(v-model="chip" active-class="accent accent-4 white--text")
                    v-chip(@click="setPreset('today')" label) Today
                    span /
                    v-chip(@click="setPreset('week')" label) Last Week
                    span /
                    v-chip(@click="setPreset('month')" label) Last Month
                    span /
                    v-chip(@click="setPreset('year')" label) Last Year
                v-col(cols="12" md="12").text-center
                  v-btn(color="primary" @click="search") Search
        // Watch
        v-dialog(:fullscreen='isMobile' persistent :value='watchDialog'  max-width='600px'   transition="dialog-bottom-transition")
          div(style ='position:relative; height: 100%;')
            watch-videos(:model ='itemCard' v-if="watchDialog" @close="watchDialog=false")
            div(style='position:absolute; top:0; width:100%;')
              v-btn.mt-2.ml-2(absolute right  icon color='danger' style="top: -45px; z-index:999;" medium,  dark='', @click.native='watchDialog=false')
                v-icon(size="30") mdi-close-box
        // Content
        v-row(no-gutters="")
            v-col(cols="12" md="12").pa-0
              v-container.explore.px-0.text-center(fluid)
                .custom-container
                  .autocomplete-block
                    img(src="@/assets/images/search-icon.png")
                    gmap-autocomplete(@place_changed="setPlace" :options="autocompleteOptions" placeholder="Search by location" v-on:keyup.enter="goSearch").location-input
                  v-btn.custom-btn(small color="" @click="dialog = true")
                    img(src="@/assets/images/filter-icon.png")
                  v-btn(small @click='view = !view' color="primary")
                      v-icon(v-if="!view") mdi-view-grid-outline
                      v-icon(v-else) mdi-earth
            // Map
            v-col(cols="12" md="12" v-if="!view")
                gmap-map(:center="center" :zoom="zoom" :options="option").map-container
                  gmap-marker(:icon='markerOptions(m)' :key="index" v-if="markers" v-for="(m, index) in markers" :animation="2" :position="m.position" :clickable="true" @click="openInfoWindow(m)")
                  gmap-info-window(v-if="markers"  :options="infoOptions" :position="infoContent.position" :opened="infoWinOpen" @closeclick="infoWinOpen=false")
                    v-card.video-card.video-card--recent(flat elevation="0")
                      .video-card__img-wrapper( :style="{ backgroundImage: 'url(' + infoContent.data.thumbnail + ')'}")
                      v-layout
                        v-flex.justify-center.video-card__title
                          h2.text-center {{infoContent.data.title}}
                      v-layout
                        v-flex.d-flex.align-center.justify-center
                          span.my-1.video-card__location.primary--text {{infoContent.data.type}}
                      v-layout
                        v-flex.d-flex.align-center.justify-center
                          img(src="@/assets/images/location-icon.png")
                          span.ml-2.video-card__location {{infoContent.data.location.direction}}, {{infoContent.data.location.state}}
                      v-layout.video-card__information
                        v-layout.d-flex.align-center.justify-center(col="6")
                          img(src="@/assets/images/calendar-icon.png")
                          p.ml-2.video-card__date {{infoContent.data.date | moment}}
                        v-layout.d-flex.align-center.justify-center(col="6")
                          img(src="@/assets/images/time-icon.png")
                          p.ml-2.video-card__time {{infoContent.data.time}}
                      v-layout
                        v-layout(col="4")
                          v-flex.d-flex.align-center.justify-center
                            p.mb-0.video-card__price {{infoContent.data.price | moneyFormat}}
                        v-layout(col="4" v-if="owned(infoContent.data)")
                            v-flex.d-flex.align-center.justify-center
                              v-btn.text-uppercase.mx-auto.video-card__buy( style='max-width: 150px;' color="#ff0000" small @click="showCard(infoContent.data)") Watch
                        v-layout(col="4" v-else)
                          v-flex.d-flex.align-center.justify-center
                            v-btn.text-uppercase.mx-auto.video-card__buy(color="#ff0000" small @click="openConfirmationDialog(infoContent.data)") Buy
                        v-layout(col="4")
                          v-flex.d-flex.align-center.justify-center
                            a.mx-auto( style='font-size: 12px;' outlined color="secondary" small @click="showCard(infoContent.data)") Show more
            // Cards
            v-col(cols="12" md="12" v-if="view && markers.length > 0").pl-2.cards-col
              v-data-iterator(:items="markers" :items-per-page.sync="itemsPerPage" :page="page")
                template(v-slot:default="props")
                  v-row(style="display:flex; justify-content:center;")
                    v-col(v-for="item in props.items" :key="item.data.name" cols="12" md="4" ).cards-col__item
                      v-card.video-card.video-card--recent()
                        .video-card__img-wrapper( :style="{ backgroundImage: 'url(' + item.data.thumbnail + ')' }")
                        v-layout
                          v-flex.justify-center.video-card__title
                            h2.text-center {{item.data.title}}
                        v-layout
                          v-flex.d-flex.align-center.justify-center
                            img(src="@/assets/images/location-icon.png")
                            span.ml-2.video-card__location {{item.data.location.direction}}, {{item.data.location.state}}
                        v-layout
                          v-flex.d-flex.align-center.justify-center
                            span.my-1.video-card__location.primary--text {{item.data.type}}
                        v-layout.video-card__information
                          v-layout.d-flex.align-center.justify-center(col="6")
                            img(src="@/assets/images/calendar-icon.png")
                            p.ml-2.video-card__date {{item.data.date | moment}}
                          v-layout.d-flex.align-center.justify-center(col="6")
                            img(src="@/assets/images/time-icon.png")
                            p.ml-2.video-card__time {{item.data.time}}
                        v-layout
                          v-layout(col="4")
                            v-flex.d-flex.align-center.justify-center
                             p.mb-0.video-card__price {{item.data.price | moneyFormat}}
                          v-layout(col="4" v-if="owned(item.data)")
                            v-flex.d-flex.align-center.justify-center
                              v-btn.text-uppercase.mx-auto.video-card__buy( style='max-width: 150px;' color="#ff0000" small @click="showCard(item.data)") Watch
                          v-layout(col="4" v-else)
                            v-flex.d-flex.align-center.justify-center
                              v-btn.text-uppercase.mx-auto.video-card__buy(color="#ff0000" small @click="openConfirmationDialog(item.data)") Buy
                          v-layout(col="4")
                            v-flex.d-flex.align-center.justify-center
                              a.mx-auto( style='font-size: 12px;' outlined color="secondary" small @click="showCard(item.data)") Show more
                              //- router-link.video-card__show-more(to="/" tag="a") Show more
            v-container(fluid)
              v-row(align="center" justify="center")
                v-col(cols="12" md="12" v-if="view && markers.length === 0").mt-10
                  v-card-title.justify-center.font-weight-light Oops! No content available at this time.
                  v-card-title.justify-center.font-weight-light Please narrow your search.
</template>

<script>
  import axios from 'axios'
  import loaders from '@/plugins/mixins/loaders'
  import * as vars from '@/vars.json'
  import moment from 'moment'
  // icons
  import accidentIcon from '@/assets/icons/search/accident.png'
  import vandalismIcon from '@/assets/icons/search/vandalism.png'
  import homeInvasion from '@/assets/icons/search/home_invasion.png'
  import crimesIcon from '@/assets/icons/search/crimes.png'
  import humanitarian from '@/assets/icons/search/humanitarian.png'
  import historicEvents from '@/assets/icons/search/historic_events.png'
  import vacationSpots from '@/assets/icons/search/unusual.png'
  import defaultIcon from '@/assets/icons/search/map-marker.png'
  const formatter = new Intl.NumberFormat('es-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    minimumIntegerDigits: 1,
  })
  export default {

    filters: {
      moneyFormat: function (value) {
        return formatter.format(value)
      },
      moment: function (date) {
        return moment(date).format('MM-DD-YYYY')
      },
    },

    components: {
      confirmDialog: () => import('@/components/custom/dialogs/ConfirmPurchase.vue'),
      WatchVideos: () => import('@/components/custom/dialogs/Videos/WatchDialog.vue'),
    },

    mixins: [loaders],

    data () {
      return {
        menu2: false,
        defaultIcon: defaultIcon,
        crimesIcon: crimesIcon,
        accidentIcon: accidentIcon,
        vandalismIcon: vandalismIcon,
        homeInvasion: homeInvasion,
        humanitarian: humanitarian,
        vacationSpots: vacationSpots,
        historicEvents: historicEvents,
        markerIcon: '',
        date: null,
        range: null,
        min: 1,
        max: 24,
        dialog: false,
        widgets: false,
        zoom: 5,
        center: {
          lat: 39.797166,
          lng: -100.955272,
        },
        category: '',
        subcategory: '',
        markers: [],
        chip: 0,
        chipType: 0,
        preset: '',
        currentPlace: null,
        option: {
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: true,
          gestureHandling: 'greedy',
        },
        autocompleteOptions: {
          componentRestrictions: {
            country: [
              'us',
            ],
          },
        },
        subCategories: {
          Accident: ['backing', 'door opening', 'driving on wrong side', 'intersection', 'lane change', 'multiple vehicle', 'other', 'rear end', 'single vehicle', 'struck parked vehicle'],
          Vandalism: ['break-in', 'club (bat,pipe)', 'keyed', 'other', 'scratched', 'shooting'],
          'Home invasion': ['burglary', 'fire', 'other', 'trespassing', 'vandalism'],
          Crimes: ['animal cruelty', 'assault & battery', 'child abuse', 'domestic abuse', 'elder abuse', 'kidnapping', 'murder', 'other', 'robbery'],
          'Historic events': ['climbing a famous mountain', 'getting into the Guinness Book of Work Record', 'milestone events', 'other'],
          Humanitarian: ['aid relief', 'education', 'financial assistance', 'food supplies', 'infrastructure', 'medical services', 'medical supplies', 'other', 'saving the earth', 'surgery', 'water supply'],
          'Unusual vacation spots': ['cave', 'desert', 'ice resort', 'island', 'mountain', 'other', 'wilderness'],
        },
        categories: [
          'Accident',
          'Vandalism',
          'Home invasion',
          'Crimes',
          'Historic events',
          'Humanitarian',
          'Unusual vacation spots',
        ],
        infoContent: {
          position: {
            lat: 37.0902405,
            lng: -95.7128906,
          },
          data: {
            location: '',
          },
        },
        infoWinOpen: false,
        // optional: offset infowindow so it visually sits nicely on top of our marker
        infoOptions: {
          pixelOffset: {
            width: -10,
            height: -95,
          },
        },
        searchTable: '',
        headers: [
          { text: 'Title', value: 'data.title' },
          { text: 'Category', value: 'data.category' },
          { text: 'Location', value: 'data.location.direction' },
        ],
        view: true,
        page: 1,
        itemsPerPage: 50,
        snackbar: {
          show: false,
          message: '',
          color: 'red',
        },
        confirmationDialog: false,
        currentItem: null,
        watchDialog: false,
        itemCard: '',
      }
    },

    computed: {
      downloads () {
        return this.$store.getters['downloads/getDownloads']
      },
      isMobile () {
        return window.innerWidth < 1024
      },
      isLoading () {
        return this.$store.getters['downloads/getLoading']
      },
      place () {
        return this.$store.getters['search/getCurrentSearch'] || null
      },
      drawer () {
        return this.$store.state.drawer.drawer
      },
      user () {
        return this.$store.state.users.user
      },
    },
    watch: {
      scrollY: function (val) {
        const scroll = window.scrollY
        return scroll
      },
      $route (to, from) {
        // console.log(to, from)
        const vm = this
        if (to.params.category) {
          vm.category = to.params.category.split('_').join(' ')
          if (to.params.subcategory) {
            vm.subcategory = to.params.subcategory.split('_').join(' ')
            vm.search()
          } else {
            vm.subcategory = ''
            vm.search()
          }
        } else {
          vm.category = ''
          vm.search()
        }
      },
    },

    mounted () {
      const vm = this
      // console.log(vm.$route)
      if (vm.$route.params.category) {
        vm.category = vm.$route.params.category.split('_').join(' ')
        if (vm.$route.params.subcategory) {
          vm.subcategory = vm.$route.params.subcategory.split('_').join(' ')
          vm.search()
        } else {
          vm.subcategory = ''
          vm.search()
        }
      }
    },

    created () {
      const vm = this
      if (vm.place.geometry) {
        vm.setPlace(vm.place)
      }
      vm.geolocate()
      vm.search()
    },

    methods: {
      owned (item) {
        // console.log(item)
        const vm = this
        if (vm.downloads) {
          const found = vm.downloads.find(element => element.url === item.url)
          if (found) {
            return true
          }
        }
        return false
      },
      showCard (card) {
        const vm = this
        vm.itemCard = card
        vm.watchDialog = true
      },
      openDialog (dialog) {
        this.$store.dispatch('dialogs/openDialog', dialog).then(function () {
          // // console.log('dialog set on : ' + dialog)
        })
      },
      markerOptions (data) {
        const vm = this
        let icon
        switch (data.data.category) {
          case 'Accident':
            icon = vm.accidentIcon
            break
          case 'Vandalism':
            icon = vm.vandalismIcon
            break
          case 'Home invasion':
            icon = vm.homeInvasion
            break
          case 'Crimes':
            icon = vm.crimesIcon
            break
          case 'Humanitarian':
            icon = vm.humanitarian
            break
          case 'Historic events':
            icon = vm.historicEvents
            break
          case 'Unusual vacation spots':
            icon = vm.vacationSpots
            break
          default :
            icon = vm.defaultIcon
            break
        }
        return {
          url: icon,
          size: { width: 70, height: 70, f: 'px', b: 'px' },
          scaledSize: { width: 50, height: 50, f: 'px', b: 'px' },
        }
      },
      setDrawer (payload) {
        this.$store.dispatch('drawer/set_drawer', payload)
      },
      setPlace (place) {
        const vm = this
        vm.center = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }
        vm.currentPlace = place
        vm.zoom = 8
        vm.search()
      },

      geolocate: function () {
        const vm = this
        navigator.geolocation.getCurrentPosition(position => {
          if (position) {
            vm.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }
          } else {
            vm.center = {
              lat: 39.797166,
              lng: -100.955272,
            }
          }
        })
      },

      setMarkers (results) {
        const vm = this
        vm.markers = []
        if (results) {
          results.forEach(element => {
            const marker = element.location.latlng
            this.markers.push({ position: marker, data: element })
          })
        }
      },

      setPreset (preset) {
        const vm = this
        vm.preset = preset
      },

      search () {
        const vm = this
        const requestData = {
          category: vm.category,
          subcategory: vm.subcategory,
          date: vm.date,
          location: vm.currentPlace,
          preset: vm.preset,
          distance: vm.range,
        }
        // console.log(requestData)
        vm.dialog = false
        axios.post(vars.urlProd + '/searchMedia', requestData).then(res => {
          // console.log(res.data)
          vm.setMarkers(res.data)
        }).catch(e => {
          // console.log(e)
        })
      },

      openInfoWindow (marker) {
        const vm = this
        vm.center = marker.position
        vm.zoom = 13
        vm.infoContent = marker
        vm.infoWinOpen = true
      },

      openConfirmationDialog (download) {
        const vm = this
        if (!vm.user) {
          vm.openDialog('login')
        } else {
          vm.currentItem = download
          vm.confirmationDialog = true
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-list-item__title{
    text-transform: capitalize;
  }
  .cards-col{
    padding-top: 100px;
    overflow-y: auto;
    overflow-x: hidden;
    &__item{
      max-width: 320px;
    }
  }
  .explore{
    position: absolute;
    z-index: 1;
    background: rgba(255,255,255,0.7);
    @media (min-width: 1024px) {
      position: relative;
    }
  }
@media (min-width: 425px) {
  .autocomplete-block{
    background-color: #bada55;
    width: max-content;
  }
}
  #map{
    height: 400px;
  }
.map-container{
  height: 100vh;
  overflow-x: hidden;
  max-height: calc(100vh - 100px);
  @media (min-width: 425px) {
    max-height: calc(100vh - 100px);
  }
}

.custom-container{
      display: flex;
      align-items: center;
      justify-content: center;
      .v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab), .v-btn.v-size--large:not(.v-btn--icon):not(.v-btn--fab){
        padding: 0 !important;
      }
    }
.autocomplete-block{
        overflow-x: hidden;
        padding: 0 1rem;
        display: grid;
        grid-template-columns: 40px max-content;
        align-items: center;
        background-color: var(--white-color);
        border-radius: 39px;
        box-shadow: -7px 4px 38px rgba(0, 0, 0, 0.16);
        height: 50px;
        @media (max-width: 1024px){
          max-width: 242px;
        }
        img{
            width: 100%;
        }
        .location-input{
            height: 100%;
            border: none;
            padding: 0 1rem;
            font-size: 12pt;
            width: 100%;
            max-width: 300px;
            &:focus{
                outline: none !important;
            }
        }
      }
.custom-btn{
        background-color: transparent !important;
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 0;
        box-shadow: none;
        position: relative;
        .v-btn__content{
          z-index: 5;
        }
        &::after{
          content: "";
          width: 50px;
          height: 50px;
          // background-color: #808184;
          // box-shadow: -3px 2px 16px 5px rgba(0, 0, 0, 0.07);
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 0;
          box-shadow: none;
        }
        img{
          width: auto;
          height: 30px;
        }
      }
.search-map{
  @media (min-width : 1024px) {
    max-width: unset;
  }
}
$primary-color: var(--secondary-color);
$text-color: var(--grey-color);
.video-card{
  min-width: 300px !important;
  margin: 0 auto;
  &__title,
  &__description,
  &__location,
  &__time,
  &__date{
    text-transform: uppercase;
    color: #a3a3a3;
  }
  &__buy{
    font-size: 18px !important;
  }
  &__show-more{
    color: var(--secondary-color) !important;
    font-size: 15px !important;
  }
  &__price{
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
  }
}
.videos{
  .section-header{
    color: $text-color;
    font-size: 19.5pt;
    line-height: 19.8pt;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    &__title, &__title-2{
      letter-spacing: -0.9pt;
      b{
        color: #8a8a8a;
        font-weight: 800;
      }
    }
    &__title-2{
      font-size: 16.3pt;
    }
    &__divider{
      width: 115.3pt;
      border-color: $primary-color !important;
      border-width: 0.5pt;
      margin:auto;
      margin-top: 7pt;
      letter-spacing: -0.81pt;
    }
  }
}

</style>
